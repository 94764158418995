"use client"

import { HeaderSearchMenuPc } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuPc"
import { HeaderUserMenuPc } from "@/app/_components/Header/HeaderUserMenuPc"
import { IconHeader } from "@/app/_components/icons/IconHeader"
import { RcLogoBase } from "@/app/_components/icons/RcLogoBase"
import { cartItemsCountAtom } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import { usePathname } from "next/navigation"
import { useMemo } from "react"
import * as styles from "./HeaderPc.css"

export function HeaderPc() {
  const cartItemsCount = useAtomValue(cartItemsCountAtom)
  const pathname = usePathname()
  const wishListSelected = useMemo(
    () => pathname === "/shopping/wishlists",
    [pathname]
  )
  const cartSelected = useMemo(() => pathname === "/shopping/carts", [pathname])

  return (
    <div>
      <div className={styles.body}>
        <div className={styles.logoSearchContainer}>
          <a href="/" className={styles.logoLink}>
            <RcLogoBase colorType="main" />
          </a>
          <HeaderSearchMenuPc />
        </div>

        <div className={styles.menuContainer}>
          <HeaderUserMenuPc />
          <a href="/shopping/wishlists" className={styles.icon}>
            <IconHeader type="wishlist" selected={wishListSelected} />
          </a>
          <a href="/shopping/carts" className={styles.icon}>
            <IconHeader
              type="cart"
              notification={cartItemsCount > 0}
              selected={cartSelected}
            />
          </a>
        </div>
      </div>
    </div>
  )
}
