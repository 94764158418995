import { Accordion } from "@/app/_components/Accordion"
import { Border } from "@/app/_components/Border"
import { FooterSNSLinks } from "@/app/_components/Footer/FooterSNSLinks"
import { FooterTextLinkBusiness } from "@/app/_components/Footer/FooterTextLinkBusiness"
import { FooterTextLinkCorp } from "@/app/_components/Footer/FooterTextLinkCorp"
import { FooterTextLinkMedia } from "@/app/_components/Footer/FooterTextLinkMedia"
import { FooterTextLinkRcs } from "@/app/_components/Footer/FooterTextLinkRcs"
import { FooterTextLinkWeb } from "@/app/_components/Footer/FooterTextLinkWeb"
import { TextLink } from "@/app/_components/TextLink"
import { media } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import * as styles from "./FooterTextLinks.css"

export function FooterTextLinks() {
  const { isDesktop } = useAtomValue(media)

  return (
    <div>
      {/* PC */}
      {isDesktop && (
        <div>
          <div className={styles.linksContainer}>
            <div>
              <p className={styles.headline}>RoomClip</p>
              <Border className={styles.border} />
              <FooterTextLinkWeb />
            </div>

            <div className={styles.rcsContainer}>
              <p className={styles.headline}>RoomClipショッピング</p>
              <Border className={styles.border} />
              <FooterTextLinkRcs />
            </div>

            <div>
              <p className={styles.headline}>オウンドメディア</p>
              <Border className={styles.border} />
              <FooterTextLinkMedia />
            </div>

            <div>
              <p className={styles.headline}>企業向けサービス</p>
              <Border className={styles.border} />
              <FooterTextLinkBusiness />
            </div>

            <div>
              <p className={styles.headline}>会社情報</p>
              <Border className={styles.border} />
              <FooterTextLinkCorp />
            </div>
          </div>
          <Border className={styles.lastBorder} />
        </div>
      )}

      {/* SP or Tablet */}
      {!isDesktop && (
        <div>
          <div className={styles.linksContainer}>
            <Accordion text="RoomClip">
              <FooterTextLinkWeb />
            </Accordion>

            <Accordion text="RoomClipショッピング">
              <FooterTextLinkRcs />
            </Accordion>

            <Accordion text="オウンドメディア">
              <FooterTextLinkMedia />
            </Accordion>

            <Accordion text="企業向けサービス">
              <FooterTextLinkBusiness />
            </Accordion>

            <Accordion text="会社情報">
              <FooterTextLinkCorp />
            </Accordion>
          </div>
        </div>
      )}

      {/* 以下はPC, SPほぼ共通 */}
      <div className={styles.footerEnd}>
        {!isDesktop && (
          <div className={styles.snsContainer}>
            <FooterSNSLinks />
          </div>
        )}

        <div className={styles.policyContainer}>
          <div className={styles.policyLinks}>
            <TextLink
              text="プライバシーポリシー"
              link="https://roomclip.jp/doc/privacy"
              type="normal"
              size="small"
            />
            <TextLink
              text="利用規約"
              link="https://roomclip.jp/doc/terms"
              type="normal"
              size="small"
            />
            <TextLink
              text="特定商取引法に基づく表示"
              link="https://roomclip.jp/doc/law"
              type="normal"
              size="small"
            />
          </div>
          <div className={styles.copyright}>&copy; RoomClip Inc.</div>
        </div>

        {isDesktop && (
          <div className={styles.snsContainer}>
            <FooterSNSLinks />
          </div>
        )}
      </div>
    </div>
  )
}
