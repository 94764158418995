"use client"

import { LineClamp } from "@/app/_components/LineClamp"
import { ArrowRightIcon } from "@/app/_components/icons/ArrowRightIcon"
import { CheckMarkIcon } from "@/app/_components/icons/CheckMarkIcon"
import { useHandleClickOutside } from "@/app/_hooks/modal"
import clsx from "clsx"
import { useCallback, useRef, useState } from "react"
import PopupMenuTriangle from "./PopupMenuTriangle.svg"
import * as styles from "./index.css"

interface LinkItem {
  text: string
  link: string
  badge?: React.ReactNode
  blank?: boolean
}

export interface SelectItem {
  id: string
  text: string
}

type ItemList = LinkItem[] | SelectItem[]

interface Props {
  items: ItemList
  opener: React.ReactNode
  selectedValue?: string
  wide?: boolean
  onClick?(id: string): void
}

export function PopupMenu({
  opener,
  items,
  selectedValue,
  wide = false,
  onClick
}: Props) {
  const [isActive, setIsActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const openerRef = useRef<HTMLDivElement>(null)
  const [popupRight, setPopupRight] = useState(0)

  const open = useCallback(() => {
    document.addEventListener("click", handleClickOutside)
    setIsActive(true)
  }, [])

  const close = useCallback(() => {
    document.removeEventListener("click", handleClickOutside)
    setIsActive(false)
  }, [])

  const handleClickOutside = useHandleClickOutside(ref, close)

  const handleClickOpener = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      open()
      e.stopPropagation()

      if (openerRef.current !== null) {
        const openerElement = openerRef.current.getBoundingClientRect()
        if (openerElement !== undefined) {
          // 基本的にpopupはopenerに対して右寄せ、48px以下の場合はtipがopenerの中央に来るように調整
          setPopupRight(Math.min(openerElement.width / 2, 24) - 24)
        }
      }
    },
    [open]
  )

  const handleSelectItem = useCallback(
    (itemId: string) => {
      if (onClick !== undefined) {
        onClick(itemId)
      }
      close()
    },
    [close, onClick]
  )

  return (
    <div className={styles.container}>
      <div className={clsx(styles.openerWrapper, wide && styles.wide)}>
        <div
          onClick={handleClickOpener}
          className={clsx(styles.opener, wide && styles.wide)}
          ref={openerRef}
        >
          {opener}
        </div>
        <div ref={ref} className={styles.popupBase}>
          {isActive && (
            <div className={styles.popupRoot} style={{ right: popupRight }}>
              <PopupMenuTriangle className={styles.tip} />

              <div className={clsx(styles.itemContainer)}>
                {items.map(item => (
                  <PopupMenuItem
                    key={item.text}
                    item={item}
                    onClick={handleSelectItem}
                    selectedValue={selectedValue}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function PopupMenuItem({
  item,
  onClick,
  selectedValue
}: {
  item: LinkItem | SelectItem
  onClick?(itemId: string): void
  selectedValue?: string
}) {
  const handleClick = useCallback(() => {
    if (onClick !== undefined && "id" in item) {
      onClick(item.id)
    }
  }, [item, onClick])

  if ("link" in item) {
    // TODO: Next移行が終わったらNextLink対応
    return (
      <a
        key={item.text}
        className={clsx(
          styles.item,
          styles.linkItem,
          item.badge !== undefined && styles.linkItemWithBadge
        )}
        href={item.link}
        target={item.blank ? "_blank" : undefined}
        rel={item.blank ? "noopener noreferrer" : undefined}
      >
        <LineClamp text={item.text} line={1} />

        {item.badge !== undefined && item.badge}

        <ArrowRightIcon className={styles.linkItemIcon} />
      </a>
    )
  }
  return (
    <div
      key={item.text}
      className={clsx(styles.item, styles.selectItem)}
      onClick={handleClick}
    >
      {item.id === selectedValue ? <CheckMarkIcon /> : <div />}
      <LineClamp text={item.text} line={1} />
    </div>
  )
}
