import { IconSNS } from "@/app/_components/icons/IconSNS"
import * as styles from "./FooterSNSLinks.css"

export function FooterSNSLinks() {
  return (
    <div className={styles.footerSNSLinks}>
      <a
        href="https://www.instagram.com/roomclipjp/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <IconSNS type="instagram" />
      </a>
      <a
        href="https://www.facebook.com/RoomClip"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <IconSNS type="facebook" />
      </a>
      <a
        href="https://twitter.com/RoomClipJP"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <IconSNS type="x" />
      </a>
      <a
        href="https://jp.pinterest.com/roomclip"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <IconSNS type="pinterest" />
      </a>
      <a
        href="https://line.me/R/ti/p/@oa-roomclipmag?from=page"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <IconSNS type="line" />
      </a>
      <a
        href="https://note.com/roomclip"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <IconSNS type="note" />
      </a>
    </div>
  )
}
