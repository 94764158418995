import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FFooter%2FFooterTextLinks.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71Sy46jMBC88xV9WSlI6whIAsRz2V9xoEm8MrZlOyHZVf59xSOEh2fmtidL7uqq7uraCpkbG0fwNwComTlzSSGCgolic4h%2BAIFDdGtC2CX6%2FhEANLx0FwpxFN2aj7GFnJRzqqYD7Blse9rdhHbExGlPVSihDIUbMxtCHBfS6UfYFgSXSC7IzxdHId7uJ4T7%2BZyTymFW2Uf6PiunXbnkVgv2oHA2vGyl2pc4rLVgDkmhxLWWlkJcmXXVqMZSYFenJryZb8OFC%2FlcuxLY7d%2B%2BpBKqodALd5JMvxwa%2B4%2F%2B%2Ft9X63j1IIWSDqWjUKB0aEaWfEZy8pOsvfYeJo7CLyWfwa8aS87AFgZRApMlbGouyZCXYxrpe9iNsJ0mznOST49iUCNzm%2FRne55wgLaLTgdN8qEyCPfnaj8moQF4vgeJh0E61V6MQgJWM7lAJgOyUtIRy%2F8ghXjfZ3mCSpeLvaz22Gc1K5Cc0DWIsscwwc%2BScIe17VsJynIhkQ0Si9gtN8tf876DZlTztRvHb87S%2BrkK7Sy2qyXesZzoMI%2BXycrL04D61BSPp13ZOmZcT%2FZdNLM0b6PZf7P7mNjDcZFY3%2Fl3q5F9i8UvlH%2BWt2iWZv9fdNw0eP4DNK6H0w4GAAA%3D%22%7D"
export var border = 'ln8rs14';
export var copyright = 'ln8rs1b ln8rs1a';
export var footerEnd = 'ln8rs16';
export var headline = 'ln8rs13 ln8rs12';
export var lastBorder = 'ln8rs15';
export var linksContainer = 'ln8rs10';
export var policyContainer = 'ln8rs18';
export var policyLinks = 'ln8rs19';
export var rcsContainer = 'ln8rs11';
export var snsContainer = 'ln8rs17';