import { FooterTextLinkLayout } from "@/app/_components/Footer/FooterTextLinkLayout"
import { TextLink } from "@/app/_components/TextLink"

export function FooterTextLinkWeb() {
  return (
    <FooterTextLinkLayout>
      <TextLink text="RoomClip" link="/" type="normal" size="small" />
      <TextLink
        text="タグ一覧"
        link="/tag"
        type="normal"
        size="small"
        isNextLink
      />
      <TextLink
        text="ユーザー一覧"
        link="/search/myroom"
        type="normal"
        size="small"
      />
      <TextLink
        text="投稿イベント一覧"
        link="/contest"
        type="normal"
        size="small"
        isNextLink
      />
      <TextLink
        text="モニターキャンペーン一覧"
        link="https://roomclip.jp/mag/category/11"
        type="normal"
        size="small"
      />
      <TextLink
        text="ショールーム一覧"
        link="/showrooms"
        type="normal"
        size="small"
      />
      <TextLink
        text="公認家づくりパートナー"
        link="https://roomclip.jp/housing_pages/"
        type="normal"
        size="small"
      />
      <TextLink
        text="RoomClipとは"
        link="/doc/entry"
        type="normal"
        size="small"
        isNextLink
      />
      <TextLink
        text="ご利用ガイド・ヘルプ"
        link="https://tunnel.zendesk.com/hc/ja"
        targetBlank
        type="normal"
        size="small"
      />
    </FooterTextLinkLayout>
  )
}
