import { IconBase } from "@/app/_components/icons/IconBase"
import Icon from "@/app/_components/icons/assets/32x136/Logo_RClogo_base00.svg"
import clsx from "clsx"
import * as styles from "./index.css"

interface Props {
  width?: number
  height?: number
  colorType?: "base" | "main"
  className?: string
}

export function RcLogoBase({
  width = 136,
  height = 32,
  colorType = "base",
  className
}: Props) {
  return (
    <IconBase
      width={width}
      height={height}
      className={clsx(colorType === "main" && styles.mainLogo, className)}
    >
      <Icon />
    </IconBase>
  )
}
