import { IconBase } from "@/app/_components/icons/IconBase"
import AndroidIcon from "@/app/_components/icons/assets/sns/Icon_Android.svg"
import AndroidWhiteIcon from "@/app/_components/icons/assets/sns/Icon_Android_white.svg"
import AppleIcon from "@/app/_components/icons/assets/sns/Icon_Apple.svg"
import AppleWhiteIcon from "@/app/_components/icons/assets/sns/Icon_Apple_white.svg"
import GoogleIcon from "@/app/_components/icons/assets/sns/Icon_Google.svg"
import FacebookIcon from "@/app/_components/icons/assets/sns/Icon_SNS_Facebook.svg"
import FacebookWhiteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_Facebook_white.svg"
import InstagramIcon from "@/app/_components/icons/assets/sns/Icon_SNS_Instagram.svg"
import InstagramWhiteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_Instagram_white.svg"
import LINEIcon from "@/app/_components/icons/assets/sns/Icon_SNS_LINE.svg"
import LINEWhiteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_LINE_white.svg"
import PinterestIcon from "@/app/_components/icons/assets/sns/Icon_SNS_Pinterest.svg"
import PinterestWhiteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_Pinterest_white.svg"
import XIcon from "@/app/_components/icons/assets/sns/Icon_SNS_X.svg"
import XWhiteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_X_white.svg"
import NoteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_note.svg"
import NoteWhiteIcon from "@/app/_components/icons/assets/sns/Icon_SNS_note_white.svg"
import { useMemo } from "react"

export type IconType =
  | "x"
  | "instagram"
  | "facebook"
  | "pinterest"
  | "line"
  | "note"
  | "android"
  | "apple"
  | "google"

interface Props {
  type: IconType
  white?: boolean
  width?: number
  height?: number
}

export function IconSNS({
  type,
  white = false,
  width = 32,
  height = 32
}: Props) {
  const memorizedIcon = useMemo(() => {
    switch (type) {
      case "x":
        return white ? <XWhiteIcon /> : <XIcon />
      case "instagram":
        return white ? <InstagramWhiteIcon /> : <InstagramIcon />
      case "facebook":
        return white ? <FacebookWhiteIcon /> : <FacebookIcon />
      case "pinterest":
        return white ? <PinterestWhiteIcon /> : <PinterestIcon />
      case "note":
        return white ? <NoteWhiteIcon /> : <NoteIcon />
      case "line":
        return white ? <LINEWhiteIcon /> : <LINEIcon />
      case "android":
        return white ? <AndroidWhiteIcon /> : <AndroidIcon />
      case "apple":
        return white ? <AppleWhiteIcon /> : <AppleIcon />
      case "google":
        return <GoogleIcon />

      default:
        null
    }
  }, [type, white])

  return (
    <IconBase width={width} height={height} currentColor={false}>
      {memorizedIcon}
    </IconBase>
  )
}
