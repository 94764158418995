import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FPopupMenu%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61Ty3LbMAy86ytwjA%2FMWH7IMXPpn3QoEZbQUCSHpBw5Hf97R5T8oOO46UxP0gCLxWIBPv%2FMG9mJUObwOwPoPDrmUWEVOGij8TU7Zs8nzCJiJHmrxIEDaUUa2U5h%2F5oBDF%2B2U%2BadQ2VU1%2Bq09vw3T1lKZaq3BLp82KbqnDeOgzWkA7ohFJzQngIZzWEv3BNjgZQONpezlPj7GlYRYM2J1qESgfapH%2BsbkCi9UV3AqMlYDi82Sj73eWAVgFBUa0YBWz8iGWo5JD4YaYn9zWwhna34UjBAK1xNmjmqm8AhL0ZVU7Q0IZiWA8uH8BXjJjK%2BkwwNh3w7zVIaJ9FxyG0P3iiSiapidsEwJyR1%2Fkb2rwnRM98IOThwnV6ImDZ7dKM9DUmJ6SVt093VjuQn%2Byo83UYtLnuwQkrS9cWCyqjhlK4VHEZ9onqrnem0TLPL2b%2Fcm4hKB4EsYGuVCMjGfXsO%2Bc6B6IKJhNgHJrEyToys49M7O3na0X3T1zdduRI%2BsKohJaOAG5J5gi6%2Fo3ESelVWxbI7%2Fs1TMfIR%2FWJl%2B6HH3Wd9zH60KEmArxyiBqElPLWk2XSR22Ju%2B1mkvzxw3gynE4MAxoqKwuFmR7u4wuN12ctUsDM6ME8fyCFfjReS4LYJ%2FVc3spoa%2FG2ATfEyDDCGRX%2Bea739NNc9gcuTwPt9LoSbYvN%2FCc8Ks%2BMfiYXo8j8GAAA%3D%22%7D"
export var container = '_1hduatb1';
export var item = '_1hduatb9 _1hduatb8';
export var itemContainer = '_1hduatb7';
export var linkItem = '_1hduatba';
export var linkItemIcon = '_1hduatbc';
export var linkItemWithBadge = '_1hduatbb';
export var opener = '_1hduatb3';
export var openerWrapper = '_1hduatb2';
export var popupBase = '_1hduatb4';
export var popupRoot = '_1hduatb5';
export var selectItem = '_1hduatbd';
export var tip = '_1hduatb6';
export var wide = '_1hduatb0';