"use client"

import { showDesktop, showMobile } from "@/app/_assets/styles/media.css"
import { HeaderPc } from "@/app/_components/Header/HeaderPc"
import { HeaderSp } from "@/app/_components/Header/HeaderSp"
import * as styles from "./Header.css"

export function Header() {
  return (
    <header className={styles.header}>
      <div className={showDesktop}>
        <HeaderPc />
      </div>
      <div className={showMobile}>
        <HeaderSp />
      </div>
    </header>
  )
}
