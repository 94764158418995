"use client"

import { LazyImage } from "@/app/_components/LazyImage"
import { appLinkBaseUrlAtom } from "@/app/_store/atom"
import { media } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import * as styles from "./FooterBanners.css"

export function FooterBanners() {
  const { isDesktop } = useAtomValue(media)
  const appLinkBaseUrl = useAtomValue(appLinkBaseUrlAtom)

  return (
    <div className={styles.footerBanners}>
      <a href="/shopping/about" className={styles.link}>
        <LazyImage
          src={
            isDesktop
              ? "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/footer/ImgHowtoRCsFooter@PC.png"
              : "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/footer/ImgHowtoRCsFooter@SP.png"
          }
          alt="HowtoRCsFooter"
          className={styles.image}
        />
      </a>
      <a href={`${appLinkBaseUrl}&place=FOOTER`} className={styles.link}>
        <LazyImage
          src={
            isDesktop
              ? "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/footer/ImgAppLinkFooter@PC.png"
              : "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/footer/ImgAppLinkFooter@SP.png"
          }
          alt="AppLinkFooter"
          className={styles.image}
        />
      </a>
    </div>
  )
}
