import { FooterTextLinkLayout } from "@/app/_components/Footer/FooterTextLinkLayout"
import { TextLink } from "@/app/_components/TextLink"

export function FooterTextLinkBusiness() {
  return (
    <FooterTextLinkLayout>
      <TextLink
        text="マーケティング支援"
        link="https://biz.roomclip.jp"
        targetBlank
        type="normal"
        size="small"
      />
      <TextLink
        text="企業公式アカウント"
        link="https://biz.roomclip.jp/business"
        targetBlank
        type="normal"
        size="small"
      />
    </FooterTextLinkLayout>
  )
}
