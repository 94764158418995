import { HeaderSearchMenuListIncrement } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuListIncrement"
import { HeaderSearchMenuListPopular } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuListPopular"
import { fetchSuggest } from "@/app/_components/Header/HeaderSearchMenu/utils"
import { IndicatorCircular } from "@/app/_components/IndicatorCircular"
import { useThrottle } from "@/app/_hooks/throttle"
import { useCallback, useEffect, useRef, useState } from "react"
import * as styles from "./HeaderSearchMenuSuggestSp.css"

interface Props {
  keyword: string
}

export function HeaderSearchMenuSuggestSp({ keyword: rawKeyword }: Props) {
  const [type, setType] = useState<"popular" | "suggest">("popular")
  const [items, setItems] = useState<{ name: string; url: string }[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const keyword = useThrottle(rawKeyword, 1000)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setType(keyword.length > 1 ? "suggest" : "popular")

    fetch()
  }, [keyword])

  const fetch = useCallback(async () => {
    if (keyword.length === 1) {
      return
    }

    setIsLoading(true)

    const response = await fetchSuggest(keyword)
    setItems(response.slice(0, 10))

    setIsLoading(false)
  }, [keyword])

  // SPの場合、キーボードの表示非表示などによってモーダルのサイズを可変にしている
  const [height, setHeight] = useState(0)
  const updateModalVisualHeight = useCallback(() => {
    if (window.visualViewport !== null && ref.current !== null) {
      setHeight(window.visualViewport.height - ref.current.offsetTop)
    }
  }, [])

  // キーボードの出現などによって画面サイズが変化した場合にモーダルサイズを更新する
  useEffect(() => {
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", updateModalVisualHeight)
      updateModalVisualHeight()
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          "resize",
          updateModalVisualHeight
        )
      }
    }
  }, [updateModalVisualHeight])

  return (
    <div className={styles.container}>
      <div
        className={styles.suggestContainer}
        style={{ height: `${height}px` }}
        ref={ref}
        body-scroll-lock-ignore=""
      >
        {isLoading ? (
          <div className={styles.loading}>
            <IndicatorCircular size="medium" />
          </div>
        ) : type === "popular" ? (
          <HeaderSearchMenuListPopular items={items} />
        ) : (
          <HeaderSearchMenuListIncrement items={items} />
        )}
      </div>
    </div>
  )
}
