"use client"

import { HeaderMenuModalSp } from "@/app/_components/Header/HeaderMenuModalSp"
import { HeaderSearchMenuSp } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuSp"
import { IconHeader } from "@/app/_components/icons/IconHeader"
import { IconHeaderNotificationBadge } from "@/app/_components/icons/IconHeader/IconHeaderNotificationBadge"
import { RcLogoBase } from "@/app/_components/icons/RcLogoBase"
import { useModal } from "@/app/_hooks/modal"
import { cartItemsCountAtom } from "@/app/_store/atom"
import { newsUnreadCountAtom } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import { usePathname } from "next/navigation"
import { useMemo } from "react"
import * as styles from "./HeaderSp.css"

export function HeaderSp() {
  const [showHamburgerMenu, handleOpenHamburgerMenu, handleCloseHamburgerMenu] =
    useModal()
  const [showSearch, handleOpenSearch, handleCloseSearch] = useModal()
  const cartItemsCount = useAtomValue(cartItemsCountAtom)
  const newsUnreadCount = useAtomValue(newsUnreadCountAtom)
  const pathname = usePathname()
  const cartSelected = useMemo(() => pathname === "/shopping/carts", [pathname])

  return (
    <>
      <div className={styles.body}>
        <div className={styles.hamburgerMenuContainer}>
          <div
            onClick={handleOpenHamburgerMenu}
            className={styles.hamburgerMenuIconContainer}
          >
            <IconHeader type="menu" />
            {newsUnreadCount > 0 && (
              <div className={styles.unreadBadge}>
                <IconHeaderNotificationBadge cart={false} />
              </div>
            )}
          </div>
        </div>

        <a href="/">
          <div className={styles.logo}>
            <RcLogoBase colorType="main" width={102} height={24} />
          </div>
        </a>

        <div className={styles.menuContainer}>
          <div onClick={handleOpenSearch}>
            <IconHeader type="search" selected={showSearch} />
          </div>
          <a href="/shopping/carts">
            <IconHeader
              type="cart"
              notification={cartItemsCount > 0}
              selected={cartSelected}
            />
          </a>
        </div>
      </div>
      <HeaderMenuModalSp
        show={showHamburgerMenu}
        onClose={handleCloseHamburgerMenu}
      />
      <HeaderSearchMenuSp show={showSearch} onClose={handleCloseSearch} />
    </>
  )
}
