"use client"

import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"

interface Props {
  show: boolean
  allowScroll?: boolean
  // div要素を先に表示するオプション。Modalを開いたあとにアニメーションさせる際に必要。
  initialRender?: boolean
  children: React.ReactNode
}

export function ModalCore({ show, allowScroll = false, children }: Props) {
  const portal = useRef<HTMLDivElement>()
  const modal = useRef<HTMLDivElement>(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!show || ready) {
      return
    }

    portal.current = document.body.appendChild(document.createElement("div"))
    // portalを変化させたあとにrenderさせるためのフックとして必要
    setReady(true)
  }, [show, ready])

  useEffect(() => {
    return () => {
      if (portal.current !== undefined) {
        portal.current.remove()
      }
      document.documentElement.style.overflow = ""
    }
  }, [])

  // モーダルが開いている間背面のスクロール防止
  useEffect(() => {
    if (allowScroll) {
      return
    }
    document.documentElement.style.overflow = show ? "hidden" : ""
  }, [show, allowScroll])

  useEffect(() => {
    if (modal.current === null || allowScroll) {
      return
    }

    // スマホデバイスの場合はモーダルが開いていてもbodyがスクロールできてしまう問題があるため対応
    disableBodyScroll(modal.current, {
      // スマホデバイスでもモーダル内はスクロールできるようにしている
      allowTouchMove: (rawElement: Element) => {
        let hasScrollableElement = false
        let el: Element | null = rawElement
        while (el !== null && el !== document.body) {
          // モーダル内で、タッチしている要素の親要素のどこかでスクロールできるならスクロールする
          if (el.scrollHeight > el.clientHeight) {
            hasScrollableElement = true
          }

          if (
            el.getAttribute("body-scroll-lock-ignore") !== null &&
            // タッチしている要素のサイズがスクロールできる状態のときだけtouch moveイベントを発火させる
            hasScrollableElement
          ) {
            return true
          }

          el = el.parentElement
        }
        return false
      }
    })

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [allowScroll])

  const modalBody = useMemo(() => <div ref={modal}>{children}</div>, [children])

  // portalのrefが変化しただけではキャッチできないのでreadyが必要
  if (!show || portal.current === undefined || !ready) {
    return null
  }

  return ReactDOM.createPortal(modalBody, portal.current)
}
