"use client"
// レスポンシブデザインの切り替えにjotaiを用いているためClient Componentしなくてはならない
// TODO: メディアクエリで切り替えることでServer Componentにするか検討する

import { FooterBanners } from "@/app/_components/Footer/FooterBanners"
import { FooterTextLinks } from "@/app/_components/Footer/FooterTextLinks"
import * as styles from "./Footer.css"

export function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <div className={styles.footerBanner}>
          <FooterBanners />
        </div>
        <FooterTextLinks />
      </div>
    </footer>
  )
}
