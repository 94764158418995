import { FooterTextLinkLayout } from "@/app/_components/Footer/FooterTextLinkLayout"
import { TextLink } from "@/app/_components/TextLink"
import * as styles from "./FooterTextLinkRcs.css"

export function FooterTextLinkRcs() {
  return (
    <div className={styles.footerTextLinkRcs}>
      <FooterTextLinkLayout>
        <TextLink
          text="RoomClipショッピング"
          link="/shopping"
          type="normal"
          size="small"
          isNextLink
        />
        <TextLink
          text="Best Item Clip"
          link="https://roomclip.jp/special/best-item-clip/workspace/"
          type="normal"
          size="small"
        />
        <TextLink
          text="商品カテゴリ一覧"
          link="/shopping/genres"
          type="normal"
          size="small"
          isNextLink
        />
        <TextLink
          text="特集一覧"
          link="/shopping/special_features"
          type="normal"
          size="small"
          isNextLink
        />
        <TextLink
          text="ショップ一覧"
          link="/shopping/shops"
          type="normal"
          size="small"
          isNextLink
        />
        <TextLink
          text="RoomClipショッピングとは"
          link="/shopping/about"
          type="normal"
          size="small"
          isNextLink
        />
        <TextLink
          text="RoomClipポイントとは"
          link="https://roomclip.jp/doc/point"
          type="normal"
          size="small"
        />
      </FooterTextLinkLayout>

      <div>
        <FooterTextLinkLayout>
          <TextLink
            text="注文方法"
            link="https://tunnel.zendesk.com/hc/ja/sections/39945544883353-%E3%81%94%E6%B3%A8%E6%96%87%E3%81%AB%E9%96%A2%E3%81%97%E3%81%A6"
            targetBlank
            type="normal"
            size="small"
          />
          <TextLink
            text="支払い方法"
            link="https://tunnel.zendesk.com/hc/ja/sections/39939775600025-%E6%94%AF%E6%89%95%E3%81%84%E6%96%B9%E6%B3%95%E3%81%AB%E9%96%A2%E3%81%97%E3%81%A6"
            targetBlank
            type="normal"
            size="small"
          />
          <TextLink
            text="キャンセル・返品・交換"
            link="https://tunnel.zendesk.com/hc/ja/sections/39940534658457-%E3%82%AD%E3%83%A3%E3%83%B3%E3%82%BB%E3%83%AB-%E8%BF%94%E5%93%81-%E4%BA%A4%E6%8F%9B-%E8%BF%94%E9%87%91%E3%81%AB%E9%96%A2%E3%81%97%E3%81%A6"
            targetBlank
            type="normal"
            size="small"
          />
          <TextLink
            text="ショッピングのお問い合わせ"
            link="https://tunnel.zendesk.com/hc/ja/requests/new"
            targetBlank
            type="normal"
            size="small"
          />
          <TextLink
            text="その他ご利用ガイド・ヘルプ"
            link="https://tunnel.zendesk.com/hc/ja/categories/39939703255833"
            targetBlank
            type="normal"
            size="small"
          />
        </FooterTextLinkLayout>
      </div>
    </div>
  )
}
