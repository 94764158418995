import { FooterTextLinkLayout } from "@/app/_components/Footer/FooterTextLinkLayout"
import { TextLink } from "@/app/_components/TextLink"

export function FooterTextLinkMedia() {
  return (
    <FooterTextLinkLayout>
      <TextLink
        text="RoomClip mag"
        link="https://roomclip.jp/mag/"
        type="normal"
        size="small"
      />
      <TextLink
        text="10000人の暮らし"
        link="https://roomclip.jp/mag/archives/57472"
        type="normal"
        size="small"
      />
      <TextLink
        text="RoomClip関連書籍・出版物"
        link="/doc/publications"
        type="normal"
        size="small"
      />
      <TextLink
        text="RoomClip住文化研究所"
        link="https://lab.roomclip.jp/"
        type="normal"
        size="small"
      />
    </FooterTextLinkLayout>
  )
}
