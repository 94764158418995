"use client"

import { PointsPt } from "@/app/_components/PointsPt"
import { PopupMenu } from "@/app/_components/PopupMenu"
import { UnreadLabel } from "@/app/_components/UnreadLabel"
import { UserIcon } from "@/app/_components/UserIcon"
import { IconHeader } from "@/app/_components/icons/IconHeader"
import { IconHeaderNotificationBadge } from "@/app/_components/icons/IconHeader/IconHeaderNotificationBadge"
import { account as accountAtom } from "@/app/_store/atom"
import { newsUnreadCountAtom } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import { useMemo } from "react"
import * as styles from "./HeaderUserMenuPc.css"

export function HeaderUserMenuPc() {
  const account = useAtomValue(accountAtom)
  const newsUnreadCount = useAtomValue(newsUnreadCountAtom)

  if (account === undefined) {
    return null
  }

  if (account === null) {
    return (
      <a href="/account/login" className={styles.icon}>
        <IconHeader type="user" />
      </a>
    )
  }

  const items = useMemo(
    () => [
      {
        text: "マイページ",
        link: `/myroom/${account.id}`
      },
      {
        text: "お知らせ",
        link: "/news",
        // お知らせがあるときはバッジを表示する
        badge: newsUnreadCount > 0 ? <UnreadLabel /> : undefined
      },
      {
        text: "注文履歴",
        link: "/shopping/orders"
      },
      {
        text: "お問い合わせ一覧",
        link: "/shopping/messages"
      },
      {
        text: "タイムライン",
        link: "/timeline"
      },
      {
        text: "投稿",
        link: "/photo_upload"
      },
      {
        text: "設定",
        link: "/settings/account"
      },
      {
        text: "ログアウト",
        link: "/account/logout"
      }
    ],
    [newsUnreadCount, account]
  )

  return (
    <div className={styles.container}>
      {/* 社内からメガメニューに被りやすいという意見が出たため一旦非表示 */}
      {/* <span className={styles.userName}>{account.name}さん</span>
      <PointsPt point={currentPoint} className={styles.point} /> */}
      <PopupMenu
        items={items}
        opener={
          <div className={styles.userIconContainer}>
            <UserIcon
              imageUrl={account.image}
              name={account.name}
              size="small"
            />
            {newsUnreadCount > 0 && (
              <div className={styles.unreadBadge}>
                <IconHeaderNotificationBadge cart={false} />
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
