"use client"

import { cartItemsCountAtom } from "@/app/_store/atom"
import clsx from "clsx"
import { useAtomValue } from "jotai"
import { useMemo } from "react"
import * as styles from "./IconHeaderNotificationBadge.css"

interface Props {
  cart: boolean
}

export function IconHeaderNotificationBadge({ cart }: Props) {
  const cartItemsCount = useAtomValue(cartItemsCountAtom)
  const count = useMemo(() => Math.min(cartItemsCount, 99), [cartItemsCount])

  if (cart) {
    return <span className={clsx(styles.badge, styles.isCart)}>{count}</span>
  }
  return <span className={clsx(styles.badge, styles.notCart)} />
}
