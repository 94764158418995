import { IconBase } from "@/app/_components/icons/IconBase"
import { IconHeaderNotificationBadge } from "@/app/_components/icons/IconHeader/IconHeaderNotificationBadge"
import LikeOffIcon from "@/app/_components/icons/assets/24x24/IconActionLikeOff.svg"
import CartIcon from "@/app/_components/icons/assets/24x24/IconHeaderCart.svg"
import MenuCloseIcon from "@/app/_components/icons/assets/24x24/IconHeaderClose.svg"
import MenuIcon from "@/app/_components/icons/assets/24x24/IconHeaderMenu.svg"
import LogoutUserIcon from "@/app/_components/icons/assets/24x24/IconHeaderUser.svg"
import SearchIcon from "@/app/_components/icons/assets/24x24/icon_search_button.svg"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./index.css"

export type IconType = "user" | "cart" | "menu" | "wishlist" | "search"

interface Props {
  type: IconType
  notification?: boolean
  selected?: boolean
  shopping?: boolean
}

export function IconHeader({
  type,
  notification = false,
  selected = false
}: Props) {
  const memorizedIcon = useMemo(() => {
    switch (type) {
      case "user":
        return <LogoutUserIcon className={clsx(selected && styles.selected)} />
      case "cart":
        return <CartIcon className={clsx(selected && styles.selected)} />
      case "wishlist":
        return <LikeOffIcon className={clsx(selected && styles.selected)} />
      case "search":
        return <SearchIcon className={clsx(selected && styles.selected)} />
      default:
        return selected ? (
          <MenuCloseIcon className={styles.selected} />
        ) : (
          <MenuIcon />
        )
    }
  }, [type, selected])

  return (
    <IconBase
      width={24}
      height={24}
      currentColor={selected}
      className={clsx(styles.base, type === "cart" && styles.cart)}
    >
      {memorizedIcon}

      {notification && (
        <div className={styles.badge}>
          <IconHeaderNotificationBadge cart={type === "cart"} />
        </div>
      )}
    </IconBase>
  )
}
