import { HeaderSearchMenuSuggestSp } from "@/app/_components/Header/HeaderSearchMenu/HeaderSearchMenuSuggestSp"
import { TextFieldSearch } from "@/app/_components/TextFieldSearch"
import { usePathname, useSearchParams } from "next/navigation"
import { useCallback, useEffect, useRef, useState } from "react"
import * as styles from "./HeaderSearchMenuSp.css"

interface Props {
  show: boolean
  onClose(): void
}

export function HeaderSearchMenuSp({ show, onClose }: Props) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState("")

  useEffect(() => {
    if (pathname === "/shopping/search") {
      setValue(searchParams.get("keyword") ?? "")
    }
  }, [searchParams, pathname])

  const handleClearSearch = useCallback(() => {
    setValue("")
  }, [])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }, [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.nativeEvent.isComposing || e.key !== "Enter") {
        return
      }
      const url = new URL("/tag/search", process.env.NEXT_PUBLIC_ORIGIN)
      url.searchParams.append("keyword", value)

      // 広告があるためSPA遷移は使わない
      window.location.href = url.href

      onClose()
    },
    [value, onClose]
  )

  const handleClickSearch = useCallback(() => {
    const url = new URL("/tag/search", process.env.NEXT_PUBLIC_ORIGIN)
    url.searchParams.append("keyword", value)

    // 広告があるためSPA遷移は使わない
    window.location.href = url.href

    onClose()
  }, [value, onClose])

  useEffect(() => {
    return () => {
      document.documentElement.style.overflow = ""
    }
  }, [])

  useEffect(() => {
    // 検索メニューが開いている間背面のスクロール防止
    document.documentElement.style.overflow = show ? "hidden" : ""

    // 検索メニューが開いたときはinputにfocusする
    if (show && inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [show])

  if (!show) {
    return null
  }

  return (
    <div ref={ref}>
      <div className={styles.searchContainer}>
        <TextFieldSearch
          onClear={handleClearSearch}
          onSearch={handleClickSearch}
          variant="small"
          placeholder="検索する"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
        <div onClick={onClose} className={styles.cancelText}>
          キャンセル
        </div>
      </div>

      <div className={styles.suggest}>
        <HeaderSearchMenuSuggestSp keyword={value} />
      </div>
    </div>
  )
}
