import { FooterTextLinkLayout } from "@/app/_components/Footer/FooterTextLinkLayout"
import { TextLink } from "@/app/_components/TextLink"

export function FooterTextLinkCorp() {
  return (
    <FooterTextLinkLayout>
      <TextLink
        text="運営会社"
        link="https://corp.roomclip.jp/"
        targetBlank
        type="normal"
        size="small"
      />
      <TextLink
        text="お問い合わせ"
        link="https://roomclip.jp/contact"
        type="normal"
        size="small"
      />
      <TextLink
        text="プレスリリース"
        link="https://corp.roomclip.jp/news"
        targetBlank
        type="normal"
        size="small"
      />
    </FooterTextLinkLayout>
  )
}
