import { HeaderUserMenuSp } from "@/app/_components/Header/HeaderUserMenuSp"
import { ListArrowLink } from "@/app/_components/List/ListArrowLink"
import { ModalCore } from "@/app/_components/Modal/ModalCore"
import { IconBase } from "@/app/_components/icons/IconBase"
import CloseIcon from "@/app/_components/icons/assets/24x24/IconHeaderClose.svg"
import { useCallback, useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import * as styles from "./HeaderMenuModalSp.css"

interface Props {
  show: boolean
  onClose(): void
}

export function HeaderMenuModalSp({ show, onClose }: Props) {
  const [open, setOpen] = useState(show)
  const nodeRef = useRef(null)

  const handleClickMask = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) {
      setOpen(false)
    }
  }, [])

  const handleClickCloseIcon = useCallback(() => {
    setOpen(false)
  }, [])

  const handleExitSlide = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    // openのアニメーションを走らせるためにタイミング上必要
    setTimeout(() => {
      setOpen(show)
    }, 50)
  }, [show])

  return (
    <ModalCore show={show}>
      <div className={styles.mask} onClick={handleClickMask}>
        <div className={styles.layout}>
          <CSSTransition
            nodeRef={nodeRef}
            in={open}
            timeout={200}
            classNames={styles.transitionGroup}
            onExited={handleExitSlide}
          >
            <div className={styles.modal} ref={nodeRef}>
              <HeaderUserMenuSp />
              <ListArrowLink
                link="/shopping/wishlists"
                text="お気に入りリスト"
              />
              <ListArrowLink link="/tag-groups" text="コーディネート" />
              <ListArrowLink link="/shopping/genres" text="商品カテゴリ" />
              <ListArrowLink link="/shopping/shops" text="ショップ" />
              <ListArrowLink link="/shopping/special_features" text="特集" />
            </div>
          </CSSTransition>
          <div className={styles.close} onClick={handleClickCloseIcon}>
            <IconBase width={24} height={24} currentColor>
              <CloseIcon />
            </IconBase>
          </div>
        </div>
      </div>
    </ModalCore>
  )
}
