"use client"

import { Accordion } from "@/app/_components/Accordion"
import { ListArrowLink } from "@/app/_components/List/ListArrowLink"
import { UnreadLabel } from "@/app/_components/UnreadLabel"
import { UserIcon } from "@/app/_components/UserIcon"
import { IconHeaderNotificationBadge } from "@/app/_components/icons/IconHeader/IconHeaderNotificationBadge"
import { account as accountAtom } from "@/app/_store/atom"
import { newsUnreadCountAtom } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import * as styles from "./HeaderUserMenuSp.css"

export function HeaderUserMenuSp() {
  const account = useAtomValue(accountAtom)
  const newsUnreadCount = useAtomValue(newsUnreadCountAtom)

  if (account === undefined) {
    return null
  }

  if (account === null) {
    return <ListArrowLink link="/account/login" text="ログイン・新規会員登録" />
  }

  const dummy = <div className={styles.dummy} />

  return (
    <div>
      <Accordion
        thumbnail={
          <div className={styles.userIconContainer}>
            <UserIcon
              imageUrl={account.image}
              name={account.name}
              size="small"
            />
            {newsUnreadCount > 0 && (
              <div className={styles.unreadBadge}>
                <IconHeaderNotificationBadge cart={false} />
              </div>
            )}
          </div>
        }
        text={`${account.name}`}
      >
        <ListArrowLink
          text="マイページ"
          link={`/myroom/${account.id}`}
          thumbnail={dummy}
          borderTop
        />
        <ListArrowLink
          text="お知らせ"
          link="/news"
          thumbnail={dummy}
          badge={newsUnreadCount > 0 ? <UnreadLabel /> : undefined}
        />
        <ListArrowLink
          text="注文履歴"
          link="/shopping/orders"
          thumbnail={dummy}
        />
        <ListArrowLink
          text="お問い合わせ一覧"
          link="/shopping/messages"
          thumbnail={dummy}
        />
        <ListArrowLink text="タイムライン" link="/timeline" thumbnail={dummy} />
        <ListArrowLink text="設定" link="/settings/account" thumbnail={dummy} />
        <ListArrowLink
          text="ログアウト"
          link="/account/logout"
          thumbnail={dummy}
          borderBottom={false}
        />
      </Accordion>
    </div>
  )
}
